// extracted by mini-css-extract-plugin
export var bgGrey100 = "OrgStats-module--bg-grey-100--7a4a8";
export var bgGrey150 = "OrgStats-module--bg-grey-150--79d4b";
export var bgGrey200 = "OrgStats-module--bg-grey-200--9d7ef";
export var bgGrey300 = "OrgStats-module--bg-grey-300--73615";
export var bgGrey400 = "OrgStats-module--bg-grey-400--de40f";
export var bgGrey500 = "OrgStats-module--bg-grey-500--b0946";
export var bgGrey600 = "OrgStats-module--bg-grey-600--a1d0e";
export var bgGrey700 = "OrgStats-module--bg-grey-700--16adc";
export var bgGrey800 = "OrgStats-module--bg-grey-800--27239";
export var bgGrey900 = "OrgStats-module--bg-grey-900--38fe7";
export var ratingBoxCls = "OrgStats-module--ratingBoxCls--bb664";
export var starsCls = "OrgStats-module--starsCls--4eaa3";
export var statBoxCls = "OrgStats-module--statBoxCls--10f47";
export var statCls = "OrgStats-module--statCls--7c9bf";
export var statLableCls = "OrgStats-module--statLableCls--4c1f5";
export var statsWrapperCls = "OrgStats-module--statsWrapperCls--ddc1a";
export var textGrey100 = "OrgStats-module--text-grey-100--542d9";
export var textGrey150 = "OrgStats-module--text-grey-150--347d6";
export var textGrey200 = "OrgStats-module--text-grey-200--1d572";
export var textGrey300 = "OrgStats-module--text-grey-300--f8280";
export var textGrey400 = "OrgStats-module--text-grey-400--feafe";
export var textGrey500 = "OrgStats-module--text-grey-500--32854";
export var textGrey600 = "OrgStats-module--text-grey-600--91b88";
export var textGrey700 = "OrgStats-module--text-grey-700--3659a";
export var textGrey800 = "OrgStats-module--text-grey-800--f5c99";
export var textGrey900 = "OrgStats-module--text-grey-900--e047b";