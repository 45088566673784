import React from "react"
import { Container, Row } from "react-bootstrap"
import { Section, SectionHeading, TextBlock } from "../../UI/Common"

import OrgStats from "./OrgStats"
const CompanyStats = ({ sectionHeading, paragraph, config, stats, googleReviewData }) => {

  const { padding } = config || {}
  
  const {
    googlePlacesPlace: { rating },
  } = googleReviewData
  
  return (
    <Section padding={padding}>
      <Container className="text-center">
        <SectionHeading
          text={sectionHeading}
          alignment="center"
          showUnderline={false}
        />
        <TextBlock text={paragraph} alignment="center" mode="muted" />
        <Row className="justify-content-center mt-3">
        <OrgStats stats={stats} rating={rating}/>
        </Row>
      </Container>
    </Section>
  )
}

export default CompanyStats
