import { graphql } from "gatsby"
import React from "react"
import CompanyStats from './CompanyStats'

export const fragment = graphql`
  fragment CompanyStatsFragment on WpPage_Flexlayouts_FlexibleLayouts_CompanyStats {
    sectionHeading
    paragraph
    stats {
      stat
      label
    }    
    config {
      padding {
         top {
           topMobile
           topTablet
           topDesktop
         }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`
export const ACFCompanyStats = ({
  googleReviewData,
  sectionHeading,
  paragraph,
  config,
  stats
}) => {
  return (
    <CompanyStats
      googleReviewData={googleReviewData}
      sectionHeading={sectionHeading}
      paragraph={paragraph}
      config={config}
      stats={stats} 
    />
  )
}
